// layout.js
import React, { useState } from 'react';
import MainSidebar from './Sidebar';
import RightSidebar from './right-sidebar';
import Footer from './Footer';

const Layout = ({ children }) => {
  // Left sidebar collapse state (already present)
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // Right sidebar collapse state
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(false);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-1 w-full">
        <MainSidebar onCollapse={setIsSidebarCollapsed} />
        <main
          className={`flex-1 overflow-auto bg-gray-200 transition-all duration-300 
                      ${isSidebarCollapsed ? 'ml-[80px]' : 'ml-[250px]'} 
                      ${isRightSidebarCollapsed ? 'mr-0' : 'mr-64'}`}
        >
          <div className="container mx-auto px-4 py-6">
            {children}
          </div>
        </main>
        {/* Pass the state and its setter to RightSidebar */}
        <RightSidebar
          isCollapsed={isRightSidebarCollapsed}
          setIsCollapsed={setIsRightSidebarCollapsed}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
