import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase';
import { CSSTransition } from 'react-transition-group';
import { 
  FaHome, FaUser, FaChevronLeft, FaChevronRight, 
  FaUtensils, FaHeartbeat, FaChartLine, FaRobot, FaBars 
} from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navigation.css';
import { GiHeartPlus } from "react-icons/gi";



const menuItems = [
  { path: "/", label: "DASHBOARD", icon: <FaHome /> },
  { path: "/yourplan", label: "YOUR PLAN", icon: <FaUtensils /> },
  { path: "/trackers", label: "TRACKERS", icon: <FaChartLine /> },
  { path: "/myhealth", label: "MY HEALTH", icon: <FaHeartbeat /> },
  { path: "/profile", label: "MY PROFILE", icon: <FaUser /> },
  { path: "/center", label: "AI CENTER", icon: <FaRobot /> },
  { path: "https:///wellness-center.nutriplan.seif8911.xyz", label: " Wellness Center", icon: <GiHeartPlus  />},

];

const Navbar = ({ isLoggedIn, handleSignOut }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-[#2E6C83] fixed-top d-md-none">
      <div className="container-fluid">
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <h2 className="text-xl font-bold mb-0">NUTRIPLAN</h2>
        </Link>
        <button className={`navbar-toggler ms-auto ${isNavOpen ? 'collapsed' : ''}`} onClick={toggleNavbar} aria-expanded={isNavOpen}>
          <FaBars className="text-white" />
        </button>
        <CSSTransition in={isNavOpen} timeout={300} classNames="mobile-menu" unmountOnExit>
          <div className="mobile-menu bg-[#2E6C83]">
            <ul className="navbar-nav">
              {menuItems.map((item) => (
                <li className="nav-item" key={item.path}>
                  <Link className="nav-link" to={item.path} onClick={() => setIsNavOpen(false)}>
                    {item.label}
                  </Link>
                </li>
              ))}
              <li className="nav-item">
                <button className="nav-link sign-out-btn" onClick={() => { handleSignOut(); setIsNavOpen(false); }}>
                  {isLoggedIn ? 'SIGN OUT' : 'LOG IN'}
                </button>
              </li>
            </ul>
          </div>
        </CSSTransition>
      </div>
    </nav>
  );
};

const Sidebar = ({ isLoggedIn, handleSignOut, onCollapse }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const toggleSidebar = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem('sidebarCollapsed', JSON.stringify(newState));
    onCollapse && onCollapse(newState);
  };

  useEffect(() => {
    const storedCollapsed = localStorage.getItem('sidebarCollapsed');
    if (storedCollapsed !== null) {
      const collapsedState = JSON.parse(storedCollapsed);
      setIsCollapsed(collapsedState);
      onCollapse && onCollapse(collapsedState);
    }
  }, [onCollapse]);

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <img 
          src={isCollapsed ? "https://i.ibb.co/kgHFTJhS/image.png" : "https://i.ibb.co/TtJdfS5/image.png"} 
          alt="NutriPlan Logo" 
          className={`logo ${isCollapsed ? 'collapsed-logo' : 'expanded-logo'}`} 
        />
        {!isCollapsed && (
          <div className="branding">
            <h2>NUTRIPLAN</h2>
            <p>WHERE MEALS MEET TASTE</p>
          </div>
        )}
      </div>
      <nav className="sidebar-nav">
        {menuItems.map((item) => (
          <NavLink key={item.path} to={item.path} className={({ isActive }) => `nav-item ${isActive ? 'active' : ''} ${isCollapsed ? 'collapsed' : ''}`}>
            <span className="icon">{item.icon}</span>
            {!isCollapsed && <span className="label">{item.label}</span>}
          </NavLink>
        ))}
      </nav>
      <div className="sidebar-footer">
        <button className="sign-out-btn" onClick={handleSignOut}>
          {!isCollapsed && (isLoggedIn ? 'SIGN OUT' : 'LOG IN')}
        </button>
        <button className="toggle-btn" onClick={toggleSidebar}>
          {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
        </button>
      </div>
    </div>
  );
};

const MainSidebar = ({ onCollapse }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    auth.signOut().then(() => {
      localStorage.removeItem('sidebarCollapsed');
      navigate('/login');
    });
  };

  if (loading) return null;
  if (!isLoggedIn) return null;

  return (
    <>
      <Sidebar isLoggedIn={isLoggedIn} handleSignOut={handleSignOut} onCollapse={onCollapse} />
      <Navbar isLoggedIn={isLoggedIn} handleSignOut={handleSignOut} />
    </>
  );
};

export default MainSidebar;
