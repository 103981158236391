import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="w-full bg-[#2E6C83] text-white py-4 animate-fadeIn z-[10000]">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center mb-4 md:mb-0">
          <img 
            src="https://i.ibb.co/kgHFTJhS/image.png" 
            alt="NutriPlan Logo" 
            className="h-12 w-12 mr-2"
          />
          <div>
            <NavLink to="/" className="text-white text-lg font-bold">
              Nutriplan
            </NavLink>
            <p className="text-xs">&copy; 2025 All rights reserved</p>
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-4 mb-4 md:mb-0">
          <NavLink to="/" className="hover:underline text-sm">Home</NavLink>
          <NavLink to="/trackers" className="hover:underline text-sm">Trackers</NavLink>
          <NavLink to="/yourplan" className="hover:underline text-sm">Plans</NavLink>
          <NavLink to="/myhealth" className="hover:underline text-sm">My Health</NavLink>
          <NavLink to="/profile" className="hover:underline text-sm">Profile</NavLink>
          <NavLink to="/center" className="hover:underline text-sm">AI Center</NavLink>
        </div>

        <div className="text-center md:text-right">
          <p className="text-sm">
            Developed by <a href="https://nova-sites.uk" className="hover:underline">Nova-Sites</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
