// right-sidebar.js
import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { useLocation } from 'react-router-dom';
import { onSnapshot, doc } from 'firebase/firestore';
import {
  FiChevronLeft,
  FiChevronRight,
  FiDroplet,
  FiActivity,
  FiTarget
} from 'react-icons/fi';

const DEFAULT_AVATAR_URL = 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg?20200418092106';

const dailyTips = [
  'Drink at least 8 glasses of water a day!',
  'Take a 10-minute walk after each meal to stay active.',
  'Eat 5 servings of fruits and vegetables daily.',
  'Get at least 7 hours of sleep to recharge.',
  'Stretch for 10 minutes every morning to loosen up.',
  'Never give up on your dreams—keep pushing forward!',
  'Prioritize your mental health—take time for yourself.',
  'Aim for 30 minutes of exercise most days of the week.',
  'Practice gratitude daily to improve your outlook on life.',
  'Choose whole grains over refined ones for better energy.',
  'Set small, achievable goals to keep yourself motivated.',
  'Keep a journal to track your progress and reflect on your journey.',
  'Find a workout buddy to stay accountable and make fitness fun.',
  'Incorporate mindfulness or meditation into your daily routine.',
  'Limit screen time to improve your sleep quality.',
  'Celebrate your successes, no matter how small they seem.',
  'Don’t compare yourself to others—focus on your own progress.',
  'Aim to eat a colorful variety of fruits and vegetables.',
  'Stay hydrated by drinking water before, during, and after meals.',
  'Practice positive self-talk to boost your confidence and motivation.',
  'Take regular breaks during work to avoid burnout.',
  'Enjoy a hobby or activity you love to reduce stress.',
  'Avoid sugary drinks—opt for water or herbal teas instead.',
  'Remember, setbacks are just setups for comebacks.',
  'Listen to your body and give it the rest it needs.',
  'Surround yourself with supportive and encouraging people.',
  'Keep your workspace organized to boost productivity.',
  'Try to include protein in every meal to stay full longer.',
  'Stay consistent with your exercise routine for lasting results.',
  'Practice deep breathing exercises to calm your mind.',
  'Focus on progress, not perfection—every step counts!',
  'Eat mindfully—enjoy and savor each bite.',
  'Find joy in the journey, not just the destination.',
  'Set aside time for relaxation to recharge your batteries.',
  'Take care of your emotional health as much as your physical health.',
  'Make sleep a priority for better overall health.',
  'Learn to say no to things that drain your energy.',
  'Practice kindness to others and yourself—it’s good for the soul.',
  'Aim to walk 10,000 steps a day for better fitness.',
  'Avoid processed foods—opt for fresh and natural ingredients.',
  'Keep a water bottle with you to remind yourself to stay hydrated.',
  'Set a regular sleep schedule to improve your sleep quality.',
  'Embrace challenges as opportunities to grow.',
  'Make healthy snacks like nuts and fruits easily accessible.',
  'Regularly review your goals and adjust them as needed.',
  'Surround yourself with inspiration—quotes, images, or people.',
  'Commit to a healthy lifestyle, not just a quick fix.',
  'Learn something new every day to keep your mind sharp.',
  'Practice forgiveness—let go of grudges to reduce stress.',
  'Keep moving forward, no matter how slow the progress may seem.',
];

const RightSidebar = ({ isCollapsed, setIsCollapsed }) => {
  const [currentUserData, setCurrentUserData] = useState({});
  const [healthData, setHealthData] = useState({});
  const [dailyTip, setDailyTip] = useState(dailyTips[0]);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Use the passed-in state to toggle collapse
  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let userUnsubscribe = () => {};
    let healthUnsubscribe = () => {};

    const setupListeners = async () => {
      if (auth.currentUser && location.pathname !== '/login') {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        const healthRef = doc(db, 'health', auth.currentUser.uid);

        userUnsubscribe = onSnapshot(userRef, (doc) => {
          if (doc.exists) setCurrentUserData(doc.data());
        });

        healthUnsubscribe = onSnapshot(healthRef, (doc) => {
          if (doc.exists) setHealthData(doc.data());
        });
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) setupListeners();
      else {
        setCurrentUserData({});
        setHealthData({});
      }
    });

    return () => {
      unsubscribe();
      userUnsubscribe();
      healthUnsubscribe();
    };
  }, [location.pathname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDailyTip(dailyTips[Math.floor(Math.random() * dailyTips.length)]);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const isVisible = !['/login', '/error'].includes(location.pathname);
  if (!isVisible || isMobile) return null;

  return (
    <div
      className={`fixed right-0 top-0 h-full transition-all duration-300 z-[1000]
        ${isCollapsed ? 'w-0' : 'w-72 shadow-2xl'}`}
    >
      <button
        onClick={toggleSidebar}
        className={`absolute top-1/2 -left-8 transform -translate-y-1/2 bg-[#2e6c83]/90
          p-3 rounded-l-lg shadow-md hover:bg-[#6ce5e8] transition-colors
          backdrop-blur-sm border border-r-0 border-[#2e6c83]`}        
        aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      >
        {isCollapsed ? (
          <FiChevronLeft className="w-5 h-5" />
        ) : (
          <FiChevronRight className="w-5 h-5" />
        )}
      </button>

      <div className="h-full bg-white border-l border-gray-100 flex flex-col">
        <div className="p-6 border-b border-gray-100">
          <div className="flex items-center space-x-4">
            <img
              src={currentUserData?.photoURL || DEFAULT_AVATAR_URL}
              alt="User avatar"
              className="w-12 h-12 rounded-full border-2 border-blue-100"
            />
            <div>
              <h2 className="font-semibold text-gray-800">
                {currentUserData?.displayName || 'User'}
              </h2>
              <p className="text-sm text-gray-500">Health Tracker</p>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6 space-y-6">
        <div className="space-y-4">
            <h3 className="flex items-center font-semibold text-gray-700">
              <FiActivity className="mr-2 text-blue-500" /> Activity Metrics
            </h3>
            <MetricItem label="Weight" value={`${healthData?.weight || '--'} kg`} />
            <MetricItem label="Height" value={`${healthData?.height || '--'} cm`} />
            <MetricItem label="Steps" value={healthData?.steps || '--'} />
          </div>

          <div className="space-y-4">
            <h3 className="flex items-center font-semibold text-gray-700">
              <FiTarget className="mr-2 text-green-500" /> Daily Goals
            </h3>
            <ProgressMetric
              label="Calories"
              current={healthData?.caloriesConsumed || 0}
              goal={healthData?.dailyCalorieGoal}
              unit="kcal"
            />
            <ProgressMetric
              label="Steps"
              current={healthData?.steps || 0}
              goal={healthData?.dailyStepGoal}
            />
            <ProgressMetric
              label="Hydration"
              current={healthData?.waterIntake || 0}
              goal={healthData?.dailyHydrationGoal}
              unit="glasses"
            />
          </div>

          <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
            <h3 className="flex items-center text-sm font-semibold text-blue-800 mb-2">
              <FiDroplet className="mr-2" /> Periodic Tips
            </h3>
            <p className="text-sm text-blue-700 leading-relaxed">{dailyTip}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const MetricItem = ({ label, value }) => (
  <div className="flex justify-between items-center text-sm">
    <span className="text-gray-500">{label}</span>
    <span className="font-medium text-gray-700">{value}</span>
  </div>
);

const ProgressMetric = ({ label, current, goal, unit }) => {
  const progress = goal ? Math.min((current / goal) * 100, 100) : 0;
  
  return (
    <div className="space-y-1">
      <div className="flex justify-between text-sm">
        <span className="text-gray-500">{label}</span>
        <span className="font-medium text-gray-700">
          {current || 0}/{goal || '--'} {unit || ''}
        </span>
      </div>
      <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
        <div
          className="h-full bg-blue-500 rounded-full transition-all duration-500"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default RightSidebar;