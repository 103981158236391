import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useIsMobile } from './hooks/useIsMobile';
import Layout from './components/layout.js';
import PrivateRoute from './components/PrivateRoute';
import LoadingFallback from './components/LoadingFallback';

const Dashboard = React.lazy(() => import('./components/Dashboard'));
const YourPlan = React.lazy(() => import('./pages/YourPlan'));
const MyHealth = React.lazy(() => import('./pages/MyHealth'));
const Trackers = React.lazy(() => import('./components/Trackers'));
const Profile = React.lazy(() => import('./components/Profile'));
const Onboarding = React.lazy(() => import('./components/Onboarding'));
const Auth = React.lazy(() => import('./components/Auth'));
const Error = React.lazy(() => import('./pages/error'));
const AICenter = React.lazy(() => import('./components/center'));
const NutriBot = React.lazy(() => import('./components/nutribot'));
const MealPlanner = React.lazy(() => import('./components/meal-planner'));
const NotFound = React.lazy(() => import('./pages/notfound'));
const Wellnesscenter = React.lazy(() => import('./components/wellnesscenter'));


const App: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      window.location.href = 'https://m.dashboard.nutriplan.seif8911.xyz';
      return;
    }
  }, [isMobile]);

  if (isMobile) return null;

  return (
    <React.Suspense fallback={<LoadingFallback />}>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Auth />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/error" element={<Error />} />

        {/* Protected routes */}
        <Route path="/" element={
          <PrivateRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/yourplan" element={
          <PrivateRoute>
            <Layout>
              <YourPlan />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/myhealth" element={
          <PrivateRoute>
            <Layout>
              <MyHealth />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/trackers" element={
          <PrivateRoute>
            <Layout>
              <Trackers />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/profile" element={
          <PrivateRoute>
            <Layout>
              <Profile />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/center" element={
          <PrivateRoute>
            <Layout>
              <AICenter />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/nutribot" element={
          <PrivateRoute>
            <Layout>
              <NutriBot />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/meal-planner" element={
          <PrivateRoute>
            <Layout>
              <MealPlanner />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="/wellness-center" element={
          <PrivateRoute>
            <Layout>
              <Wellnesscenter />
            </Layout>
          </PrivateRoute>
        } />
        <Route path="*" element={
          <PrivateRoute>
            <Layout>
              <NotFound />
            </Layout>
          </PrivateRoute>
        } />
      </Routes>
    </React.Suspense>
  );
};

export default App;
